import React from 'react'
import Collection from '../common/collection'

function CollectionN({ props }) {
  return (
    <Collection
      src={[
        'https://volverse-negro.s3.us-east-2.amazonaws.com/images/25+Nueva2.jpg',
      ]}
      alt="Panal"
    />
  )
}


export default CollectionN
