import React from 'react'
import Collection from '../common/collection'

function CollectionY({ props }) {
  return (
    <Collection
      src={['https://volverse-negro.s3.us-east-2.amazonaws.com/images/23.1+ni%C3%B1opanalito+refineria5.jpg']}
      alt="Niño panalito"
    />
  )
}


export default CollectionY
