export default [
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/10.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/11.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/12.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/13.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/14.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/15.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/16.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/17.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/18.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/19.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/2.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/20.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/21.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/22.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/23.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/24.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/25.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/26.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/27.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/28.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/29.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/30.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/31.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/32.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/33.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/34.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/35.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/36.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/37.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/38.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/4.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/39.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/40.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/41.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/42.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/43.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/3.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/5.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/6.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/7.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/8.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/9.jpg',
  'https://volverse-negro.s3.us-east-2.amazonaws.com/figures/f-1.jpg',
]
